.header-mobile {
  height: var(--sticky-header-height);
  position: sticky;
  top: 0;
  z-index: 30;
}

@media screen(tablet) {
  .header-mobile {
    display: none;
  }
}
