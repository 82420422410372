.otp-input-wrapper {
  flex: 1;
  margin: 0 5px;
  position: relative;
}

.otp-input {
  border: 1px solid #d1d5db;
  border-radius: 10px;
  caret-color: transparent;
  font-size: 24px;
  font-weight: 400;
  height: 60px;
  margin: 0 5px;
  outline: none;
  padding: 0;
  text-align: left;
  text-align: center;
  transition: border-width 0.2s ease;
  width: 100%;
}

.otp-input:hover {
  border-color: black !important;
}

.otp-input:focus {
  border-color: black;
}

.otp-input.error {
  animation: none;
  border-color: #ef4444;
}

.otp-input-wrapper:focus-within::after {
  animation: blink 1s steps(1) infinite;
  background-color: black;
  content: '';
  height: 70%;
  left: calc(100% / 5 + 4px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
